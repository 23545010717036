<template>
  <div class="info_wrapper">
    <div class="main_info">
      <template v-if="resultType==0">
        <van-empty description="未查询到相关服务信息"/>
      </template>
      <template v-else-if="resultType==1">
        <van-empty description="该保单已经提交过预约信息"/>
      </template>
      <template v-else-if="resultType==2">
        <van-empty class="success" image="https://bigtree-1259390879.cos.ap-guangzhou.myqcloud.com/20210915_book_success.png" description="我们已收到您的预约申请"/>
      </template>
      <template v-if="resultType!=0">
        <div class="main_info_box">
          <van-cell-group>
            <van-cell  class="text"  title="预约单号:" :value="result.reserveNo"/>
            <van-cell class="text"  title="联系人" :value="`${result.reserveUserName}  (尾号${result.reserveUserMobile})`"/>
          </van-cell-group>
        </div>
      </template>
      <div class="notice_field" v-if="resultType!=1">
        <span class="notice_text">
          {{
            resultType == 0 ? '根据您提供的保单号，未查询到相关预约信息，请确认保单号是否正确。' : `工作人员将通过尾号${result.reserveUserMobile}的手机号与您联系并确认具体预约信息，请保持手机通畅`
          }}
        </span>
      </div>
    </div>
    <div class="form_bottom_box">
      <div class="bottom_handle">
        <van-button v-if="resultType!=2" class="handle_btn" round type="info" @click="back" text="返回"/>
        <span v-else class="link" @click="back">预约其他保单</span>
      </div>
    </div>
  </div>
</template>

<script>
import {Button, Cell, CellGroup, Empty} from "vant";

export default {
  name: 'BookNursingFail',
  data() {
    return {
      result: {
        policyNo: '',
        reserveNo: '',
        reserveUserMobile: '',
        reserveUserName: '',
      },
      resultType: 0
    };
  },
  components: {
    'van-empty': Empty,
    'van-button': Button,
    'van-cell': Cell,
    'van-cell-group': CellGroup,
  },
  mounted() {
    const {result, resultType} = this.$route.params;
    result ? this.result = result : '';
    resultType ? this.resultType = resultType : '';
  },

  created() {
  },
  destroyed() {

  },
  methods: {
    back() {
      this.$router.back()
    }
  },
};
</script>
<style lang="less" scoped>
@import "index";
</style>